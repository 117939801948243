import { useEffect, useRef, useState } from 'react';
import './App.css';
import Routespages from './routes/Routespages';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Preloader from './components/Preloader/Preloader';

function App() {
  const cursorRef = useRef(null);
  const cursorPointerRef = useRef(null);
  const [disableScroll, setDisableScroll] = useState(true); // State to control scrolling

  useEffect(() => {
    const cursor = cursorRef.current;
    const cursorPointer = cursorPointerRef.current;

    const handleMouseMove = (e) => {
      cursor.style.left = e.clientX + "px";
      cursor.style.top = e.clientY + "px";
      cursorPointer.style.left = e.clientX + "px";
      cursorPointer.style.top = e.clientY + "px";
    };

    const handleMouseDown = () => {
      cursor.style.height = "0.7rem";
      cursor.style.width = "0.7rem";
      cursorPointer.style.height = "3rem";
      cursorPointer.style.width = "3rem";
    };

    const handleMouseUp = () => {
      cursor.style.height = "0.3rem";
      cursor.style.width = "0.3rem";
      cursorPointer.style.height = "2rem";
      cursorPointer.style.width = "2rem";
    };

    document.body.addEventListener("mousemove", handleMouseMove);
    document.body.addEventListener("mousedown", handleMouseDown);
    document.body.addEventListener("mouseup", handleMouseUp);

    // Function to enable scrolling after 3 seconds
    const enableScrolling = () => {
      setTimeout(() => {
        setDisableScroll(false);
      }, 5500); // 5 seconds
    };

    enableScrolling(); // Call the function to start the countdown

    return () => {
      document.body.removeEventListener("mousemove", handleMouseMove);
      document.body.removeEventListener("mousedown", handleMouseDown);
      document.body.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  useEffect(() => {
    // Function to disable scrolling
    const handleScroll = (e) => {
      if (disableScroll) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    if (disableScroll) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.body.style.overflow = 'unset'; // Enable scrolling
    }

    document.body.addEventListener('wheel', handleScroll, { passive: false });

    return () => {
      document.body.removeEventListener('wheel', handleScroll);
    };
  }, [disableScroll]);

  return (
    <div>
      <Preloader />
      <div className="cursor" ref={cursorRef} />
      <div className="cursor-pointer" ref={cursorPointerRef} />
      <Routespages />
      <ToastContainer />
    </div>
  );
}

export default App;
